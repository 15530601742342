import React, { useEffect } from 'react';



const PrivacyPolicy = () => {

   useEffect(() => {

   }, [])
   return (
      <>

         <div className='page-wrapper flex-grow-1 page-block'>
            <div className="pageContainer">
               <div className="container container--small container--big__text">
                  <h1 className="h1">Integritetspolicy Webblabbet</h1>
                  <p>Gäller från och med 19:e september 2023 </p>
                  <p> Integritetspolicy Felinae Productions AB (Webblabbet)
                     Gäller från och med 19:e september 2023</p>
                  <h6>1. Inledning</h6>
                  <div>
                     <p>
                        Välkommen till Felinae Productions AB (Webblabbet).
                        Vår målsättning är att du, efter att ha läst denna policy, ska känna dig säker på att din personliga integritet respekteras och att dina personuppgifter behandlas korrekt. Vi arbetar därför också kontinuerligt med att säkerställa att vår behandling av personuppgifter till fullo sker i enlighet med gällande lagstiftning och då främst Dataskyddsförordningen (GDPR).
                     </p>

                  </div>

                  <h6>2. Vad är en personuppgift och vad innebär behandling av personuppgifter? </h6>
                  <div>
                     <p>
                        2.1 Personuppgifter är all information som direkt, eller indirekt tillsammans med andra uppgifter, kan kopplas till en levande fysisk person. En icke-uttömmande lista av exempel på personuppgifter är bl.a.:
                     </p>
                     <ul>
                        <li>Namn</li>
                        <li>	Personnummer</li>
                        <li>	Mail-adress</li>
                        <li>	Kreditkortsnummer</li>
                        <li>	IP-adress</li>
                        <li>	Bilder</li>
                        <li>	Användardata</li>

                     </ul>
                     <p>
                        2.2 Behandling av personuppgifter innefattar varje åtgärd som vidtas med personuppgifterna, oberoende av om den utförs automatiserat eller ej. Det här innebär att bl.a. följande åtgärder omfattas:
                     </p>
                     <ul>
                        <li>Insamling</li>
                        <li>Registrering</li>
                        <li>Användning</li>
                        <li>Sammanförande</li>
                        <li>Överföring</li>
                        <li>Radering</li>


                     </ul>
                  </div>

                  <h6>3. För vem gäller policyn? </h6>
                  <div>
                     <p>
                        Denna Integritetspolicy är i första hand tillämplig på samtliga individer som registrerar sig via någon av våra kontaktformulär.
                     </p>
                  </div>

                  <h6>4. För vad gäller policyn?</h6>
                  <div>
                     <p>Denna Integritetspolicy reglerar hur vi på Felinae Productions AB (Webblabbet) samlar in och behandlar personuppgifter.</p>
                  </div>

                  <h6>5. Vad innebär det att vara Personuppgiftsansvarig?</h6>
                  <div>
                     <p>
                        Personuppgiftsansvarig är en fysisk eller juridisk person eller annat organ som bestämmer ändamålet och medlen för behandlingen av personuppgifter. Ett företag är personuppgiftsansvarig med avseende på personuppgifter de innehar för egen räkning om sina anställda, kunder, leverantörer och andra.
                     </p>
                  </div>

                  <h6>6. Felinae Productions AB som Personuppgiftsansvarig </h6>
                  <div>
                     <p> <b>Felinae Productions AB</b> (org.nr. 559216-1433, Dumpergatan 3, 442 40 Kungälv) är personuppgiftsansvarig för den behandling som sker avseende dina personuppgifter och ansvarar för att sådan behandling sker i enlighet med tillämplig lagstiftning. </p>
                  </div>

                  <h6>7. Varför får vi behandla personuppgifter?</h6>
                  <div>
                     <p>7.1 För att det ska vara tillåtet att behandla personuppgifter måste det alltid finnas ett stöd i GDPR, en så kallad rättslig grund. En sådan rättslig grund är bl.a </p>
                     <ul>
                        <li>ett samtycke från den Registrerade</li>
                        <li>att personuppgiftsbehandlingen är nödvändig för att Felinae Productions ska kunna kontakta dem.</li>

                     </ul>
                     <p>7.2 Felinae Productions AB behandlar alltid dina personuppgifter i enlighet med tillämplig lagstiftning. Vår främsta grund för att behandla dina personuppgifter är att det är nödvändigt för att fullgöra vårt avtal med dig. </p>
                     <p>7.3 Det finns också situationer där vi har ett berättigat intresse av att behandla dina personuppgifter, t.ex. vid intresse av att få marknadsföra oss mot besökare i våra digitala kanaler eller vid intresse av att utveckla vår plattform.</p>
                     <p>7.4 För det fall att vi på Felinae Production AB skulle behandla dina personuppgifter för något ändamål som enligt tillämplig lagstiftning kräver ditt samtycke, så kommer vi alltid att inhämta ditt samtycke i förväg.</p>
                     <p>7.5 Det kan förekomma att samma personuppgift behandlas både med stöd av fullgörande av ett avtal, specifikt av samtycke eller med stöd av att uppgiften är nödvändig för att uppfylla andra rättsliga förpliktelser. Detta innebär att även om du återkallar ditt samtycke och den behandling som grundas på samtycket upphör, kan personuppgiften ändå finnas kvar hos oss för andra ändamål. I huvudsak behandlar vi dock endast dina uppgifter för att fullgöra ett avtal i vilket du är part. För mer information, kontakta marcus@Webblabbet.com</p>
                  </div>

                  <h6>8. Vilka personuppgifter samlar vi in? </h6>
                  <div>
                     <p>Vi samlar endast in namn, e-post och telefonnummer.</p>
                     <p>8.1 Vi hanterar dina personuppgifter för att:</p>


                     <ul>
                        <li>Kunna kontakt dig för eventuella tjänster.</li>
                        <li>Informera dig om personliga och anpassade erbjudanden, kampanjer och förmåner från oss och våra samarbetspartners, via t.ex. e-post och sms</li>

                     </ul>

                     <p>8.1.2 Lagringstid: </p>
                     <p>Vi sparar dina personuppgifter tills du ber oss att ta bort dem.</p>
                     <p>8.2 När du kommunicerar med oss</p>
                     <p>
                        Du kan välja att kommunicera med oss på flertalet sätt, bland annat genom våra konton i sociala medier eller via epost, marcus@Webblabbet.com
                        När du kommunicerar med oss så hanterar vi följande uppgifter som du själv lämnar till oss:
                     </p>
                     <ul>
                        <li>
                           Ditt namn och kontaktinformation (telefonnummer, epost)
                        </li>
                        <li>Information om din fråga, synpunkt eller ärende</li>
                     </ul>
                     <p>8.2.1 Vi hanterar dina personuppgifter för att:</p>
                     <p>Besvara dina frågor och hantera ditt ärende</p>

                     <p>8.3 Rättslig grund för hanteringen:</p>
                     <p>Vi hanterar dina personuppgifter med stöd i vårt och ditt berättigade intresse av att hantera ärendet (intresseavvägning).</p>


                     <p>8.3.1 Lagringstid:</p>
                     <p>För att säkra spårbarhet sparar vi uppgifter om vår kommunikation med dig i 12 månader.</p>

                  </div>

                  <h6>9. För vilka ändamål behandlas personuppgifter? </h6>
                  <div>
                     <p>De personuppgifter om dig som samlas in i anslutning till att du använder kontaktar oss kommer att behandlas av Felinae productions AB för följande ändamål: </p>
                     <ul>
                        <li>För att vi ska kunna kommunicera med dig, exempelvis för att möjliggöra kundvård och kundservice, vid kontakt via telefon, epost, notifikationer samt genom våra konton i sociala medier. </li>
                        <li>För marknadsföringsändamål, däribland för marknadsföring via epost, som du givetvis kan avregistrera dig ifrån via en länk i varje separat utskick </li>
                     </ul>
                  </div>
                  <h6>10. Hur länge sparar vi personuppgifter? </h6>
                  <div>
                     <p> 10.1 Dina personuppgifter sparas endast under den period som det finns ett behov av att spara dem för att fullgöra de ändamål som uppgifterna samlades in för i enlighet med denna Integritetspolicy. Felinae Productions AB kan komma att spara uppgifterna längre om det behövs för att följa lagkrav. </p>

                  </div>

                  <h6>11. Våra åtgärder för att skydda dina personuppgifter</h6>
                  <div>
                     <p>11.1 Vi på Felinae Productions AB har säkerställt att vi har vidtagit lämpliga tekniska och organisatoriska åtgärder för att skydda dina personuppgifter mot bland annat förlust, missbruk och obehörig åtkomst.</p>

                  </div>

                  <h6>12. När delar vi med oss av personuppgifter?</h6>
                  <div>
                     <p>12.1 Felinae Productions AB kommer inte att sälja, lämna ut eller sprida personuppgifter till tredje part. </p>
                     <p>12.2 Vidare kan vi lämna ut personuppgifter om vi är skyldiga till det enligt gällande lag, domstolsbeslut eller om sådant utlämnande annars är nödvändigt för att medverka till en rättslig utredning.</p>
                  </div>

                  <h6>13. Dina rättigheter </h6>
                  <div>
                     <p>13.1 Felinae Productions AB ansvarar för att dina personuppgifter behandlas i enlighet med gällande lagstiftning.</p>
                     <p>13.2 Felinae productions AB kommer på din begäran eller på eget initiativ att rätta, avidentifiera, radera eller komplettera uppgifter som upptäcks vara felaktiga, ofullständiga eller missvisande.
                     </p>
                     <p>13.3 Du har rätt att av begära tillgång till dina personuppgifter. Det innebär att du har rätt att begära ett registerutdrag över den behandling som vi genomför avseende dina personuppgifter. Du har även rätt att få en kopia av de personuppgifter som behandlas. Du har rätt att en gång per kalenderår, genom en skriftligt undertecknad ansökan, kostnadsfritt erhålla ett registerutdrag från om vilka personuppgifter som finns registrerade om dig, ändamålen med behandlingen och till vilka mottagare uppgifterna har lämnats eller ska lämnas ut. Du har även rätt att i registerutdraget få information om den förutsedda period under vilken uppgifterna kommer att lagras eller kriterierna som används för att fastställa denna period.
                     </p>
                     <p>13.4 Du har rätt till rättelse av dina personuppgifter. Vi kommer på din begäran att så snabbt som möjligt rätta de felaktiga eller ofullständiga uppgifter vi behandlar om dig.
                     </p>
                     <p> 13.5 Du har rätt till radering av dina personuppgifter. Det innebär att du har rätt att begära att dina personuppgifter tas bort om de inte längre är nödvändiga för det ändamål de samlades in för. Det kan dock finnas lagkrav på att vi inte omedelbart får radera dina personuppgifter i exempelvis bokförings- och skattelagstiftning. Vi kommer då att avsluta den behandling som görs för andra ändamål än att följa lagstiftningen.
                     </p>
                     <p> 13.6 Du har rätt att invända mot personuppgiftsbehandling som utförs med stöd av en intresseavvägning. Om du invänder mot sådan behandling kommer vi enbart att fortsätta behandlingen om det finns berättigade skäl till behandlingen som väger tyngre än dina intressen.
                     </p>
                     <p>13.7 Om du inte vill att vi behandlar dina personuppgifter för direkt marknadsföring har du alltid rätt att invända mot sådan behandling genom att antingen avregistrera dig direkt i varje specifikt epostmeddelande, alternativt skicka ett mail till marcus@Webblabbet.com. När har vi mottagit din invändning kommer vi att upphöra med att behandla personuppgifterna för sådant marknadsföringsändamål. Om du inte är nöjd med hur vi hanterar dina personuppgifter har du också möjlighet att anmäla vår behandling av dina personuppgifter till Integritetsskyddsmyndigheten. För smidig och effektiv hantering rekommenderar vi dock att du i första hand hör av dig till oss så att vi kan hjälpa dig med eventuella frågor och funderingar.
                     </p>
                  </div>


                  <h6>14. Ändringar i denna policy</h6>
                  <div>
                     <p>Felinae productions AB förbehåller sig rätten att revidera denna Integritetspolicy från tid till annan. Datumet för den senaste ändringen anges i slutet av Integritetspolicyn. Om vi gör några ändringar i Integritetspolicyn kommer vi att publicera dessa ändringar på plattformen. Du rekommenderas därför att läsa denna Integritetspolicy regelbundet för att uppmärksamma eventuella ändringar. Om vi ändrar Integritetspolicyn på ett sätt som väsentligt skiljer sig från vad som angavs när ditt samtycke samlades in, kommer vi att underrätta dig om dessa förändringar och vid behov be dig om att på nytt samtycka till Felinae Productions personuppgiftsbehandling.
                     </p>

                  </div>

                  <h6>15. Ändringar i denna policy </h6>
                  <div>
                     <p>Felinae productions AB förbehåller sig rätten att revidera denna Integritetspolicy från tid till annan. Datumet för den senaste ändringen anges i slutet av Integritetspolicyn. Om vi gör några ändringar i Integritetspolicyn kommer vi att publicera dessa ändringar på plattformen. Du rekommenderas därför att läsa denna Integritetspolicy regelbundet för att uppmärksamma eventuella ändringar. Om vi ändrar Integritetspolicyn på ett sätt som väsentligt skiljer sig från vad som angavs när ditt samtycke samlades in, kommer vi att underrätta dig om dessa förändringar och vid behov be dig om att på nytt samtycka till Felinae Productions personuppgiftsbehandling.
                     </p>
                  </div>

                  <h6>16. Kontakt</h6>
                  <div>
                     <p>Tveka inte att kontakta oss om du har några frågor om denna Integritetspolicy, behandlingen av dina personuppgifter eller om du vill begära ett registerutdrag.
                     </p>
                     <p><b>Vår kontaktinformation hittar du nedan.</b></p>
                     <address><b>Felinae Productions AB</b>  (org.nr. 559216-1433) Dumpergatan 3, 442 40 Kungälv</address>
                     <p>Mail: <b><a href="mailto:marcus@filmlabbet.com">marcus@filmlabbet.com</a></b></p>
                  </div>
               </div>
            </div>
         </div>
      </>

   )
}

export default PrivacyPolicy;