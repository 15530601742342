import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import MainWrapper from '../component/MainWrapper';

import ScrollToTop from '../pages/ScrollToTop';

import Webblabbet from '../pages/Webblabbet';
import ThankYou from '../pages/ThankYou';
import PrivacyPolicy from '../pages/privacyPolicy';


const Routing = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes >
                <Route element={<MainWrapper />}>
                    <Route path="/" element={<Webblabbet />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/thank-you" element={<ThankYou />} />
                </Route>
            </Routes>
        </Router>
    )
}

export default Routing;

