import { Button, Container, Image, Row, Col, Form } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { contactUs } from '../store/api/course.api'
import {isMobile} from 'react-device-detect';

const Webblabbet = () => {
    const navigate = useNavigate();
    const sliderRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const settings = {
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: isMobile ? 10000:20000,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            }
        ],
        beforeChange: (current, next) => {
            setActiveSlide(next);
            console.log(next)
        }
    };
    const settings1 = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centermode: true,
        centerPadding: "0px",
        autoplay: true,
    };
    const OpenContact = () => {
        navigate('/kontakt');
    }
    const [value, setValue] = useState({ email: "" });
    const dispatch = useDispatch();
    let require = []
    const onChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        // Scroll the HTML and body elements to the top
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }, []);
    const oncheckboxchange = (e) => {
        if (e.target.checked && !require.includes(e.target.value)) {
            require.push(e.target.value)
        } else {
            let index = require.indexOf(e.target.value); // 1
            console.log(index)
            require.splice(index, 1)

        }
        console.log(require)
    }
    const onAdd = () => {
        let data = {
            email: value.email,
            phone: value?.phone,
            message: value?.message,
            name: value?.name,
            business_name: value?.business_name,
            require_service: require.toString(),
            from_studio: false
        }
        dispatch(contactUs(data))
            .unwrap()
            .then((res) => {
                console.log(res.status)
                if (res.status === 200) {
                    navigate('/thank-you');
                }

            })
            .catch(() => {

            });
    };
    const openCal = () => {
        window.open('https://cal.com/filmlabbet', '_blank')
    }

    

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };
    const handleOnClick = index => {
        sliderRef.current.slickGoTo(index);
      };
    return (
        <div className="webblabet-inner-wrap">
            <section className="banner-wrapper">
                <Container>
                    <div className="text-center d-flex flex-column justify-content-center">
                        <div className="maxw-918 mx-auto mb-md-5 mb-3 mt-5">
                            <h1 className="font-56 text-white font-secondary mb-2 letter-space-1">
                                Webb-byrån för de <br /> mindre företagen
                            </h1>
                            <span className="letter-sm fw-light font-18 maxw-776 mx-auto d-block">
                                Vi hjälper dig att skapa en skräddarsydd hemsida som du själv
                            </span>
                            <span className="letter-sm fw-light font-18 maxw-776 mx-auto d-block">
                            eller med vår hjälp kan uppdatera över tid.
                            </span>
                            
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <a className="Gradientbutton" onClick={()=> scrollToSection('create-contact')}>
                                <div className="Gradientbutton-normalState">Kontakt</div>
                                <div className="Gradientbutton-hoverState">Kontakt</div>
                            </a>
                        </div>
                    </div>
                </Container>
            </section>

            {/* contact slider */}
            <section className="contactSlider">
                <p className="contactSlider--subtitle">Tidigare case</p>
                <h2 className="contactSlider--title">Ditt företag förtjänar en hemsida som  <span className="gradient-text2"> gör kunder av besökare </span></h2>
                <Slider className="contactSlider-wrap" ref={sliderRef} {...settings}>
                    <div className="element" onClick={()=>handleOnClick(0)}>
                        {activeSlide === 0 &&
                            <Image src="./assets/images/Docens-ezgif.com-video-to-gif-converter.gif" alt="Slide 2" />
                        }
                        {activeSlide != 0 &&
                            <Image src="./assets/images/docens-ezgif.com-png-to-webp-converter.webp" alt="Slide 1" />
                        }

                    </div>
                    <div className="element" onClick={()=>handleOnClick(1)} >
                        {activeSlide === 1 &&
                            <Image src="./assets/images/walljoy-ezgif.com-video-to-gif-converter.gif" alt="Slide 2" />
                        }
                        {activeSlide != 1 &&
                            <Image src="./assets/images/wallpen-ezgif.com-png-to-webp-converter.webp" alt="Slide 1" />
                        }
                    </div>
                    <div className="element" onClick={()=>handleOnClick(2)}  >
                        {activeSlide === 2 &&
                            <Image src="./assets/images/home-gif-new.gif" alt="Slide 2" />
                        }
                        {activeSlide != 2 &&
                            <Image src="./assets/images/webb1.png" alt="Slide 1" />
                        }
                    </div>
                    <div className="element" onClick={()=>handleOnClick(3)}  >
                        {activeSlide === 3 &&
                            <Image src="./assets/images/home-gif-new.gif" alt="Slide 2" />
                        }
                        {activeSlide != 3 &&
                            <Image src="./assets/images/webb1.png" alt="Slide 1" />
                        }
                    </div>
                    <div className="element" onClick={()=>handleOnClick(4)} >
                        {activeSlide === 4 &&
                            <Image src="./assets/images/home-gif-new.gif" alt="Slide 2" />
                        }
                        {activeSlide != 4 &&
                            <Image src="./assets/images/webb1.png" alt="Slide 1" />
                        }
                    </div>
                </Slider>
                <div className="d-flex align-items-center justify-content-center">
                    <a className="Gradientbutton" onClick={()=> scrollToSection('create-contact')}>
                        <div className="Gradientbutton-normalState">Kontakt</div>
                        <div className="Gradientbutton-hoverState">Kontakt</div>
                    </a>
                </div>
            </section>

            <section className="webblabbet-middle-gradient">
                <div className="left-gradient"></div>
                {/* listing section */}
                <div className="ListingSection">
                    <Container fluid>
                        <p className="ListingSection--subtitle">Workflow</p>
                        <h2 className="ListingSection--title">Hur det går till</h2>
                        <div className="ListingSection--blockswrap">
                            <div className="ListingSection--block">
                                <span>
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8 18.0859L10.8 21.6859C10.8 22.8173 10.8 23.383 10.4486 23.7345C10.0971 24.0859 9.53142 24.0859 8.40005 24.0859L7.20005 24.0859C6.06868 24.0859 5.50299 24.0859 5.15152 23.7345C4.80005 23.383 4.80005 22.8173 4.80005 21.6859L4.80005 20.4859C4.80005 19.3546 4.80005 18.7889 5.15152 18.4374C5.50299 18.0859 6.06868 18.0859 7.20005 18.0859L10.8 18.0859Z" fill="white" stroke="white" stroke-width="2.4" />
                                        <path d="M18 10.8867L18 7.28672C18 6.15535 18 5.58966 18.3515 5.23819C18.7029 4.88672 19.2686 4.88672 20.4 4.88672L21.6 4.88672C22.7314 4.88672 23.2971 4.88672 23.6485 5.23819C24 5.58966 24 6.15535 24 7.28672L24 8.48672C24 9.61809 24 10.1838 23.6485 10.5352C23.2971 10.8867 22.7314 10.8867 21.6 10.8867L18 10.8867Z" fill="white" stroke="white" stroke-width="2.4" />
                                        <path d="M18 10.8867L10.8 18.0867" stroke="white" stroke-width="2.4" />
                                    </svg>
                                </span>
                                <h6>1. Wireframe</h6>
                                <p>Vi hjälper dig att skapa en skräddarsydd hemsida i wordpress som du själv eller med vår hjälp kan uppdatera över tid.</p>
                            </div>
                            <div className="ListingSection--block">
                                <span>
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.645 9.04065L19.8001 6.88558C20.4544 6.23128 20.7815 5.90413 20.9564 5.55121C21.2891 4.87975 21.2891 4.0914 20.9564 3.41994C20.7815 3.06703 20.4544 2.73988 19.8001 2.08559L19.8001 2.08557C19.1458 1.43128 18.8186 1.10413 18.4657 0.929242C17.7942 0.596503 17.0059 0.596503 16.3344 0.929242C15.9815 1.10413 15.6544 1.43128 15.0001 2.08558L12.8178 4.26787C13.9744 6.24858 15.6378 7.89924 17.645 9.04065ZM11.0724 6.01321L2.82772 14.2579L2.82771 14.2579C2.31765 14.768 2.06261 15.023 1.89493 15.3363C1.72725 15.6497 1.65652 16.0033 1.51505 16.7107L0.776573 20.4031C0.696747 20.8022 0.656834 21.0018 0.770363 21.1153C0.883893 21.2288 1.08346 21.1889 1.48259 21.1091L5.17497 20.3706C5.88231 20.2291 6.23598 20.1584 6.5493 19.9907C6.86262 19.823 7.11765 19.568 7.62773 19.0579L15.8951 10.7905C13.9491 9.57175 12.3031 7.937 11.0724 6.01321Z" fill="white" fill-opacity="0.9" />
                                    </svg>

                                </span>
                                <h6>2. Design</h6>
                                <p>Vi hjälper dig att skapa en skräddarsydd hemsida i wordpress som du själv eller med vår hjälp kan uppdatera över tid.</p>
                            </div>
                            <div className="ListingSection--block">
                                <span>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4 0.685547C5.4353 0.685547 0.599976 5.52087 0.599976 11.4855C0.599976 17.4502 5.43531 22.2855 11.4 22.2855H16.8C18.4774 22.2855 19.3161 22.2855 19.9776 22.0115C20.8597 21.6461 21.5606 20.9453 21.9259 20.0632C22.2 19.4016 22.2 18.5629 22.2 16.8855V11.4855C22.2 5.52087 17.3647 0.685547 11.4 0.685547ZM6.59988 10.2854C6.59988 9.62261 7.13714 9.08535 7.79988 9.08535H14.9999C15.6626 9.08535 16.1999 9.62261 16.1999 10.2854C16.1999 10.9481 15.6626 11.4854 14.9999 11.4854H7.79988C7.13714 11.4854 6.59988 10.9481 6.59988 10.2854ZM10.2 15.0852C10.2 14.4224 10.7372 13.8852 11.4 13.8852H15C15.6627 13.8852 16.2 14.4224 16.2 15.0852C16.2 15.7479 15.6627 16.2852 15 16.2852H11.4C10.7372 16.2852 10.2 15.7479 10.2 15.0852Z" fill="white" fill-opacity="0.9" />
                                    </svg>

                                </span>
                                <h6>3. Feedback</h6>
                                <p>Vi hjälper dig att skapa en skräddarsydd hemsida i wordpress som du själv eller med vår hjälp kan uppdatera över tid.</p>
                            </div>
                            <div className="ListingSection--block">
                                <span>
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3267 4.2222L6.94667 7.41223C6.01002 7.88056 5.54169 8.11473 5.54169 8.48555C5.54169 8.85637 6.01002 9.09053 6.94667 9.55886L6.94668 9.55886L13.3267 12.7489C13.8534 13.0122 14.1168 13.1439 14.4 13.1439C14.6833 13.1439 14.9467 13.0122 15.4734 12.7489L21.8534 9.55886C22.7901 9.09053 23.2584 8.85637 23.2584 8.48555C23.2584 8.11473 22.7901 7.88056 21.8534 7.41223L15.4734 4.2222C14.9467 3.95886 14.6833 3.82719 14.4 3.82719C14.1168 3.82719 13.8534 3.95886 13.3267 4.2222Z" fill="white" fill-opacity="0.9" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.42604 12.6738C9.15079 13.0629 10.0043 13.4897 10.9866 13.9808L10.9866 13.9808L12.2535 14.6143L12.2535 14.6143C13.3068 15.141 13.8335 15.4043 14.4001 15.4043C14.9666 15.4043 15.4933 15.141 16.5467 14.6143L17.8136 13.9808C18.7959 13.4897 19.6494 13.0629 20.3741 12.6738L21.8535 13.4135C22.7901 13.8818 23.2585 14.116 23.2585 14.4868C23.2585 14.8576 22.7901 15.0918 21.8535 15.5601L15.4734 18.7502C14.9467 19.0135 14.6834 19.1452 14.4001 19.1452C14.1168 19.1452 13.8535 19.0135 13.3268 18.7502L6.94673 15.5601L6.94673 15.5601L6.94672 15.5601C6.01007 15.0918 5.54175 14.8576 5.54175 14.4868C5.54175 14.116 6.01008 13.8818 6.94673 13.4135L8.42604 12.6738Z" fill="white" fill-opacity="0.9" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.42604 18.6738C9.15079 19.0629 10.0043 19.4897 10.9866 19.9808L10.9866 19.9808L12.2535 20.6143L12.2535 20.6143C13.3068 21.141 13.8335 21.4043 14.4001 21.4043C14.9666 21.4043 15.4933 21.141 16.5467 20.6143L17.8136 19.9808C18.7959 19.4897 19.6494 19.0629 20.3741 18.6738L21.8535 19.4135C22.7901 19.8818 23.2585 20.116 23.2585 20.4868C23.2585 20.8576 22.7901 21.0918 21.8535 21.5601L15.4734 24.7502C14.9467 25.0135 14.6834 25.1452 14.4001 25.1452C14.1168 25.1452 13.8535 25.0135 13.3268 24.7502L6.94673 21.5601L6.94673 21.5601L6.94672 21.5601C6.01007 21.0918 5.54175 20.8576 5.54175 20.4868C5.54175 20.116 6.01008 19.8818 6.94673 19.4135L8.42604 18.6738Z" fill="white" fill-opacity="0.9" />
                                    </svg>
                                </span>
                                <h6>4. Utveckling</h6>
                                <p>Vi hjälper dig att skapa en skräddarsydd hemsida i wordpress som du själv eller med vår hjälp kan uppdatera över tid.</p>
                            </div>
                            <div className="ListingSection--block">
                                <span>
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.79998 9.68672C4.79998 8.52142 4.80252 7.76817 4.87714 7.21316C4.94712 6.69268 5.0601 6.52954 5.15145 6.43819C5.2428 6.34684 5.40593 6.23386 5.92642 6.16389C6.48143 6.08927 7.23468 6.08672 8.39998 6.08672H20.4C21.5653 6.08672 22.3185 6.08927 22.8735 6.16389C23.394 6.23386 23.5572 6.34684 23.6485 6.43819C23.7399 6.52954 23.8528 6.69268 23.9228 7.21316C23.9974 7.76817 24 8.52142 24 9.68672V19.2867H4.79998V9.68672Z" stroke="white" stroke-opacity="0.9" stroke-width="2.4" />
                                        <path d="M4.39998 19.2863C3.29541 19.2863 2.39998 20.1818 2.39998 21.2863C2.39998 22.8327 3.65358 24.0863 5.19998 24.0863H23.6C25.1464 24.0863 26.4 22.8327 26.4 21.2863C26.4 20.1818 25.5045 19.2863 24.4 19.2863H4.39998Z" stroke="white" stroke-opacity="0.9" stroke-width="2.4" />
                                    </svg>
                                </span>
                                <h6>5. Leverans</h6>
                                <p>Vi hjälper dig att skapa en skräddarsydd hemsida i wordpress som du själv eller med vår hjälp kan uppdatera över tid.</p>
                            </div>
                        </div>
                    </Container>
                </div>

                {/* booky slider */}
                <div className="Booky-slider ">
                    <Container>
                        <div className="Booky-slider-itembox">
                            <div className="Booky-slider-itembox-innerbox">
                                <Slider {...settings1}>
                                    <div className="Booky-slider-item">
                                        <div className="Booky-slider-itemwrap">
                                            <span className="Booky-slider-logo"> booky</span>
                                            <p className="Booky-slider-text">
                                                Vi tog hjälp av Docens för att skapa en intern utbildningsfilm till en av våra kunder. Docens levererade på topp från start till slut. De var med på förmöte, de skötte hela produktionen och inkluderade vår kunds grafiska profil i redigeringen. Funderar ni på att skapa utbildningsmaterial eller utbildningsfilmer så tycker jag verkligen att ni ska kontakta Docens.
                                            </p>
                                            <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                                <img width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                                                    className="rounded-3" />
                                                <div>
                                                    <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                                                        Charlotte Dunhoff
                                                    </span>
                                                    <span className="Booky-slider-profileinfo-text2 d-block">
                                                        Grundare & VD
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Booky-slider-item">
                                        <div className="Booky-slider-itemwrap">
                                            <span className="Booky-slider-logo"> booky</span>
                                            <p className="Booky-slider-text">
                                                Vi tog hjälp av Docens för att skapa en intern utbildningsfilm till en av våra kunder. Docens levererade på topp från start till slut. De var med på förmöte, de skötte hela produktionen och inkluderade vår kunds grafiska profil i redigeringen. Funderar ni på att skapa utbildningsmaterial eller utbildningsfilmer så tycker jag verkligen att ni ska kontakta Docens.
                                            </p>
                                            <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                                <img width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                                                    className="rounded-3" />
                                                <div>
                                                    <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                                                        Charlotte Dunhoff
                                                    </span>
                                                    <span className="Booky-slider-profileinfo-text2 d-block">
                                                        Grundare & VD
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                                </div>
                        </div>
                    </Container>
                    <div className="Booky-slider-bg">
                        <div className="Booky-slider-logowrap d-flex  flex-nowrap  text-center">
                        
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo4.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo1.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo3.png" alt="Spotify" />
                            </div>

                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo4.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo1.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo3.png" alt="Spotify" />
                            </div>
                            
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo4.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo1.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo3.png" alt="Spotify" />
                            </div>

                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo4.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo1.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo3.png" alt="Spotify" />
                            </div>

                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo4.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo1.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo3.png" alt="Spotify" />
                            </div>

                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo4.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo1.png" alt="Spotify" />
                            </div>
                            <div className="logo-wrap">
                                <Image src="/assets/images/sliderlogo3.png" alt="Spotify" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-gradient"></div>
            </section>
            {/* Offer section */}
            <section className="OfferSection">
                <Container>
                    <p className="OfferSection--subtitle">ERBJUDANDE</p>
                    <h2 className="OfferSection--title"><span className="gradient-text2"> Halva priset </span> på alla hemsidor under hösten 24!</h2>
                    <div className="OfferSection--box">
                        <div className="d-flex align-items-center justify-content-between">
                            <span>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.1056 4.33393L5.78885 6.99229C5.00831 7.38257 4.61803 7.5777 4.61803 7.88672C4.61803 8.19574 5.00831 8.39087 5.78885 8.78115L11.1056 11.4395C11.5445 11.659 11.7639 11.7687 12 11.7687C12.2361 11.7687 12.4555 11.659 12.8944 11.4395L18.2111 8.78115C18.9917 8.39087 19.382 8.19574 19.382 7.88672C19.382 7.5777 18.9917 7.38257 18.2111 6.99229L12.8944 4.33393C12.4555 4.11448 12.2361 4.00475 12 4.00475C11.7639 4.00475 11.5445 4.11448 11.1056 4.33393Z" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.02217 11.375C7.62603 11.6992 8.33716 12.0548 9.15554 12.464L10.2113 12.9918C11.0891 13.4307 11.528 13.6502 12.0001 13.6502C12.4723 13.6502 12.9112 13.4307 13.789 12.9918L14.8447 12.464C15.6631 12.0548 16.3742 11.6992 16.9781 11.375L18.2113 11.9916C18.9918 12.3819 19.3821 12.577 19.3821 12.886C19.3821 13.195 18.9918 13.3902 18.2113 13.7804L12.8946 16.4388C12.4557 16.6583 12.2362 16.768 12.0001 16.768C11.7641 16.768 11.5446 16.6583 11.1057 16.4388L11.1057 16.4388L5.78898 13.7804C5.00844 13.3902 4.61816 13.195 4.61816 12.886C4.61816 12.577 5.00844 12.3819 5.78898 11.9916L7.02217 11.375Z" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.02169 16.375C7.62567 16.6993 8.33696 17.0549 9.15557 17.4642L10.2113 17.9921C11.0891 18.431 11.528 18.6504 12.0001 18.6504C12.4723 18.6504 12.9112 18.431 13.789 17.9921L14.8447 17.4642C15.6633 17.0549 16.3746 16.6993 16.9786 16.375L18.2113 16.9914C18.9918 17.3816 19.3821 17.5768 19.3821 17.8858C19.3821 18.1948 18.9918 18.3899 18.2113 18.7802L12.8946 21.4386C12.4557 21.658 12.2362 21.7677 12.0001 21.7677C11.7641 21.7677 11.5446 21.658 11.1057 21.4386L11.1057 21.4386L5.78898 18.7802C5.00844 18.3899 4.61816 18.1948 4.61816 17.8858C4.61816 17.5768 5.00844 17.3816 5.78898 16.9914L7.02169 16.375Z" fill="white" />
                                </svg>
                            </span>
                            <span className="OfferSection--box-tag">Erbjudande</span>
                        </div>
                        <p className="OfferSection--box-title">Hemsida</p>
                        <p className="OfferSection--box-subtitle">skräddarsydd hemsida efter ditt företags behov.</p>
                        <h6 className="OfferSection--box-oldprice">25.000 kr</h6>
                        <h3 className="OfferSection--box-newprice"><span> Fr. </span> 12.500 kr</h3>
                        <ul>
                            <li>Inga dolda kostnader</li>
                            <li>Workshop & brainstorming</li>
                            <li>Speciallanpassad design</li>
                            <li>Leverans inom 3 veckor</li>
                            <li>Löpande kontakt via slack / e-post</li>
                        </ul>
                        <a className="Gradientbutton w-100" onClick={()=> scrollToSection('create-contact')}>
                            <div className="Gradientbutton-normalState">Kontakt</div>
                            <div className="Gradientbutton-hoverState">Kontakt</div>
                        </a>
                    </div>
                </Container>
                <div id="create-contact" className="mt-50"></div>
            </section>

            {/* contact section */}
            <div className="contact-blockwrap position-relative">
                    <Row>
                        <Col md={6}>
                            <Form className="d-flex flex-column gap-20 mb-9">
                                <Row>
                                    <Col lg={12}>
                                        <div className="font-18 fw-light mb-32 letter-xs block mb-30">
                                            <h2>Kontakt</h2>
                                            <p>Använd formuläret för att komma i kontakt med oss. </p>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group controlId="inputName">
                                            <Form.Label>* Namn</Form.Label>
                                            <Form.Control type="text" placeholder="Namn" name='name' value={value.name} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group controlId="inputTel">
                                            <Form.Label>* Telefon</Form.Label>
                                            <Form.Control type="tel" placeholder="Telefon" name='phone' value={value.phone} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group controlId="inputPost">
                                            <Form.Label>* E-post</Form.Label>
                                            <Form.Control type="email" placeholder="E-post" name='email' value={value.email} onChange={onChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>* Företag</Form.Label>
                                            <Form.Control type="text" placeholder="Företag" name='business_name' value={value.business_name} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group>
                                            <Form.Label>Meddelande</Form.Label>
                                            <Form.Control
                                                className="contact-teaxtarea"
                                                as="textarea"
                                                placeholder="Meddelande"
                                                name="message"
                                                value={value.message} onChange={onChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={12} className=" text-md-start text-center">
                                    <a className="Gradientbutton" onClick={onAdd}>
                                        <div className="Gradientbutton-normalState">Skicka</div>
                                        <div className="Gradientbutton-hoverState">Skicka</div>
                                    </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col md={6}>
                            <div className="imgwrp">
                                <img src="/assets/images/TV-4.png"></img>
                            </div>
                        </Col>
                    </Row>
                </div>

        </div>
    );
}

export default Webblabbet;
