import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect } from 'react';
import { Container, Image, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const MainWrapper = () => {
  const location = useLocation();
  const isHomepage = location.pathname === '/';
  const isThankyou = location.pathname === '/thank-you';
  const admin = location.pathname === '/admin/list';
  const isHavsogaHomePage1 = location.pathname === '/havsoga';
  const isHavsogaHomePage2 = location.pathname === '/havsoga-old';
  const isHavsogaPage1 = location.pathname === '/havsoga-registrerad';
  const isHavsogaPage2 = location.pathname === '/havsoga-player';
  const isStudio = location.pathname.includes("studio");
  const isSport = location.pathname.includes("sport");
  const isRealEstate = location.pathname.includes("fastighet");
  const isIndividual = location.pathname.includes('individual');
  

  useEffect(() => {
    if (isHavsogaHomePage2 || isHavsogaPage1 || isHavsogaPage2 || isHavsogaHomePage1 || isIndividual) {
      document.body.classList.add('bg-black');
    } else {
      document.body.classList.remove('bg-black');
    }
  }, [isHavsogaHomePage2 || isHavsogaPage1 || isHavsogaPage2 || isHavsogaHomePage1 || isIndividual]);

  useEffect(() => {
    const isRealEstate = location.pathname === '/real-estate';
    if (isStudio || isSport || isRealEstate) {
      document.body.classList.add('bg-black-gray');
    } else {
      document.body.classList.remove('bg-black-gray');
    }
  }, [isStudio, isSport, isRealEstate]);

  return (

    <div>
      <div className="webblabbet-add">
        <div className="main-temporary-add">
          <span> Halva priset på alla hemsidor under januari 🚀 </span>
          <span>Halva priset på alla hemsidor under januari 🚀</span>
          <span>Halva priset på alla hemsidor under januari 🚀</span>
          <span>Halva priset på alla hemsidor under januari 🚀</span>
          <span>Halva priset på alla hemsidor under januari 🚀</span>
          <span>Halva priset på alla hemsidor under januari 🚀</span>
          <span>Halva priset på alla hemsidor under januari 🚀</span>
          <span>Halva priset på alla hemsidor under januari 🚀</span>
        </div>
      </div>
      <div className={`webblabbet-page pt-md-30 pt-6 `}
      >
        
          <section className="onlylogo">
            <Navbar className="bg-transparent text-white">
              <Container>
                <div className="position-relative w-100 d-flex align-items-center">
                <Link to="/"><Image src="/assets/images/webblabbet-logo.svg" alt="Fillabbet" /></Link> 
                </div>
              </Container>
            </Navbar>
          </section>
        

          <Outlet></Outlet>
        
        <Footer />
      </div>

      {/* )} */}
    </div >
  )
}

export default MainWrapper;